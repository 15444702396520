export default class NITG_Shop {

    cart;
    productList;
    template;
    storage;
    summary;
    summaryTemplate

    constructor() {
        this.cart = document.getElementById('cart');
        this.productList = document.querySelectorAll('.product');
        this.template = document.getElementById('cart-item-template');
        this.summaryTemplate = document.getElementById('summary-template')??null;
        this.initShopHandlers();
        this.loadCartFromStorage();
        this.initSummaryHandler();
        this.updateCartTotal();
        this.updateCategoryTotals();
        this.sendingOrders();
    }

    initShopHandlers() {
        this.productList.forEach((item) => {
            const category = item.getAttribute('data-category');
            const id = item.getAttribute('data-id');
            const addToCartBtn = item.querySelector('.add-to-cart');
            const removeFromCartBtn = item.querySelector('.un-cart');
            addToCartBtn.addEventListener('click', () => this.addToCartHandler(item));
            removeFromCartBtn.addEventListener('click', () => this.removeFromCartHandler(id, category));
        });
    }

    loadCartFromStorage() {
        const cartData = localStorage.getItem('cart');
        if (cartData) {
            this.storage = JSON.parse(cartData);
            this.renderCartFromStorage();
        } else {
            this.storage = {};
        }
    }

    saveCartToStorage() {
        localStorage.setItem('cart', JSON.stringify(this.storage));
    }

    renderCartFromStorage() {
        Object.keys(this.storage).forEach(categoryId => {
            const categoryItems = this.storage[categoryId];
            categoryItems.forEach(item => {
                this.setToCartByCategory(this.createCartHandler(item, this.template));
                this.productList.forEach((product) => {
                    if (product.getAttribute('data-id') === item.id) {
                        product.classList.add('added');
                    }
                })
            });
        });
    }

    addToCartHandler(item) {
        item.classList.add('added');
        const category = item.getAttribute('data-category');
        const id = item.getAttribute('data-id');

        const imgSrc = item.querySelector('.image-wrapper img')?.getAttribute('src');
        const imgSrcset = item.querySelector('.image-wrapper img')?.getAttribute('srcset');
        const imgSizes = item.querySelector('.image-wrapper img')?.getAttribute('sizes');
        const imgAlt = item.querySelector('.image-wrapper img')?.getAttribute('alt');
        const imgLoading = item.querySelector('.image-wrapper img')?.getAttribute('loading');

        const title = item.querySelector('h2.title')?.textContent;
        let description;
        if(item.classList.contains('product-type-2')) {
            const titles = item.querySelectorAll('h3');
            let newDescription = '';
            titles.forEach((item, index) => {
                let text = item.textContent.trim();
                if (text.charAt(text.length - 1) === ':') {
                    text = text.slice(0, -1);
                }
                newDescription += text;
                if (index !== titles.length - 1) {
                    newDescription += '\n';
                }
            });
            description = newDescription;

        } else {
            description = item.querySelector('.description')?.textContent;
        }
        const pricePrimary = item.querySelector('.price[data-price-from] .price-inner')?.textContent;
        const priceSecondary = item.querySelector('.price[data-price-per-hour] .price-inner')?.textContent;

        const priceFrom = item.querySelector('.price[data-price-from]')?.getAttribute('data-price-from');
        const pricePerHour = item.querySelector('.price[data-price-per-hour]')?.getAttribute('data-price-per-hour');

        const newItem = {
            id: id,
            imgSrc: imgSrc,
            imgSrcset: imgSrcset,
            imgSizes: imgSizes,
            imgAlt: imgAlt,
            imgLoading: imgLoading,
            title: title,
            description: description,
            pricePrimary: pricePrimary,
            priceSecondary: priceSecondary,
            category: category,
            priceFrom: priceFrom,
            pricePerHour: pricePerHour,
        };

        if (!this.storage[category]) {
            this.storage[category] = [];
        }
        this.storage[category].push(newItem);

        this.setToCartByCategory(this.createCartHandler(newItem, this.template));
        this.saveCartToStorage();
        this.updateCartTotal();
        this.updateCategoryTotals();
        this.updateSummaryTotal();
        this.sendingOrders();
    }

    removeFromCartHandler(id, category) {
        const index = this.storage[category].findIndex(item => item.id === id);
        if (index !== -1) {
            this.storage[category].splice(index, 1);
        }

        this.cart.querySelector(`[data-id="${id}"]`).remove();
        this.productList.forEach((item) => {
            if (item.getAttribute('data-id') === id) {
                item.classList.remove('added');
            }
        });
        this.removeFromSummaryHandler(id);

        this.saveCartToStorage();
        this.updateCartTotal();
        this.updateCategoryTotals();
        this.updateSummaryTotal();
        this.sendingOrders()
    }

    updateCartTotal() {
        const total = this.cart.querySelectorAll('.product-list__item:not(.empty)').length;
        this.cart.querySelector('.cart-toggle__short-info .cart-total').textContent = !!total ? total : '';
    }

    updateCategoryTotals() {
        const categories = this.cart.querySelectorAll('.product-category-list__item');
        categories.forEach(category => {
            const categoryId = category.querySelector('.accordion-header button').getAttribute('data-bs-target').replace('#cat-', '');
            const total = this.cart.querySelectorAll(`.product-list-wrapper#cat-${categoryId} .product-list__item:not(.empty)`).length;
            category.querySelector('.cat-total').textContent = !!total ? total : '';
        });
    }

    setToCartByCategory(data) {
        const [clone, category] = data;
        this.cart.querySelector(category)?.appendChild(clone);
    }

    createCartHandler(item, template) {
        const clone = template.content.cloneNode(true);
        clone.querySelector('article').setAttribute('data-id', item.id);
        if(item.imgSrc) {
            clone.querySelector('.image-wrapper img').setAttribute('src', item.imgSrc);
            clone.querySelector('.image-wrapper img').setAttribute('srcset', item.imgSrcset);
            clone.querySelector('.image-wrapper img').setAttribute('sizes', item.imgSizes);
            clone.querySelector('.image-wrapper img').setAttribute('alt', item.imgAlt);
            clone.querySelector('.image-wrapper img').setAttribute('loading', item.imgLoading);
        } else {
            const imgSrc = `${window.location.origin}/wp-content/themes/nitg-catalog/assets/img/seal_check_gray_60x60.svg`;
            clone.querySelector('.image-wrapper').classList.add('placeholder');
            clone.querySelector('.image-wrapper img').setAttribute('width', 60);
            clone.querySelector('.image-wrapper img').setAttribute('height', 60);
            clone.querySelector('.image-wrapper img').setAttribute('src', imgSrc);
        }
        clone.querySelector('.product-body .title').textContent = item.title;
        clone.querySelector('.product-body .description').textContent = item.description;
        if(item.pricePrimary && item.priceFrom) {
            clone.querySelector('.product-body .price-primary .price-inner').textContent = item.pricePrimary;
        } else {
            clone.querySelector('.product-body .price-primary').remove();
        }
        if(item.priceSecondary && item.pricePerHour) {
            clone.querySelector('.product-body .price-secondary .price-inner').textContent = item.priceSecondary;
        } else {
            clone.querySelector('.product-body .price-secondary').remove();
        }

        const removeFromCartBtn = clone.querySelector('.un-cart');
        removeFromCartBtn.addEventListener('click', () => this.removeFromCartHandler(item.id,item.category));
        const category = `#cat-${item.category} .product-list`;
        return [clone, category];
    }

    initSummaryHandler() {
        if(!this.summaryTemplate) return;
        const inner = document.querySelector('.cart-choice .product-list');
        Object.keys(this.storage).forEach(categoryId => {
            const categoryItems = this.storage[categoryId];
            categoryItems.forEach(item => {
                const [clone] = this.createCartHandler(item, this.summaryTemplate);
                inner.appendChild(clone);
            });
        });
        this.updateSummaryTotal();
    }

    removeFromSummaryHandler(id) {
        if(!this.summary) return;
        this.summary.forEach((item) => {
            if (item.getAttribute('data-id') === id) {
                item.remove();
            }
        });
    }

    updateSummaryTotal() {
        if(!this.summaryTemplate) return;
        this.summary = document.querySelectorAll('.cart-choice article');
        const totalCount = document.querySelector('.cart-choice .total-choice');
        const totalSum = document.querySelector('.approximate #summa');
        let totalPriceFrom = 0;
        let totalPricePerHour = 0;
        Object.keys(this.storage).forEach(categoryId => {
            const categoryItems = this.storage[categoryId];
            categoryItems.forEach(item => {
                if(item.priceFrom) {
                    totalPriceFrom += Number(item.priceFrom);
                }
                if(item.pricePerHour) {
                    totalPricePerHour += Number(item.pricePerHour);
                }
            });
        });
        totalCount.textContent = this.summary.length;
        totalSum.textContent = totalPriceFrom;
    }

    sendingOrders() {
        const fieldForOrders = document.querySelector('textarea[name="textarea-742"]');
        if(fieldForOrders) {
            let orders = '';
            Object.keys(this.storage).forEach((categoryId) => {
                const categoryItems = this.storage[categoryId];
                categoryItems.forEach((item) => {
                    orders += `- ${item.title}: ${item.pricePrimary} \n`;
                });
            });
            fieldForOrders.value = orders;
        }
    }
}
