/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
    let menuHeader = document.querySelector('header');
    let body = document.querySelector('body');
    if(menuHeader && body) {
        const scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        window.addEventListener('scroll', onWindowScroll);
        function onWindowScroll() {
            if(window.document.scrollingElement.scrollTop > 120 && scrollHeight > window.innerHeight + 200){
                menuHeader.classList.add("sticky");
                body.classList.add("sticky-margin-top");
            } else {
                body.classList.remove("sticky-margin-top");
                menuHeader.classList.remove("sticky")
            }
        }
    }
}
/* **** Main menu **** */
function mainMenuHandler() {
    const mainMenu = document.querySelector('.main-menu');
    if (!mainMenu) return;
    const menuItems = mainMenu.querySelectorAll('.main-menu__list__item');
    for(let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].classList.contains('current')) break;
        menuItems[i].classList.add('selected');
    }
}
mainMenuHandler();

/* **** Error sending CF7 **** */
// document.addEventListener( 'wpcf7invalid', function( event ) {
//     console.log(event.target);
//   }, false );

/* **** Success sending CF7 **** */
document.addEventListener( 'wpcf7mailsent', function( event ) {
    if(window.location.pathname.includes('en/')) {
        window.location.href = `${window.location.origin}/en/vielen-dank-fuer-ihre-anfrage`;
    } else {
        window.location.href = `${window.location.origin}/vielen-dank-fuer-ihre-anfrage`;
    }
  }, false );

/* **** Auto resize textarea field **** */
export class ResizeTextArea {
    constructor() {
        this.textarea = document.querySelector('textarea');
        this.rows = this.textarea.getAttribute('rows');
        this.textarea.addEventListener('input', this.autosize.bind(this));
    }

    autosize() {
        const previousRows = this.rows;
        const lines = this.textarea.value.split('\n').length;
        const characters = this.textarea.value.length;
        const rows = Math.max(lines, Math.ceil(characters / this.textarea.cols));
        if (rows !== previousRows) {
            this.textarea.setAttribute('rows', rows);
            this.rows = rows;
            this.textarea.dispatchEvent(new Event('change'));
        }
    }
}

/* **** Rules adding margin after blocks **** */
function marginRules() {
    const sections = document.querySelectorAll('section.product-description-section');
    const productCatalog = document.querySelectorAll('div.product-catalog');
    if(sections && sections.length > 0) {
        sections.forEach(section => {
            if (section.nextElementSibling && section.nextElementSibling.tagName.toLowerCase() === 'div') {
                section.classList.add('has-div');
            }
        });
    }
    if(productCatalog && productCatalog.length > 0) {
        productCatalog.forEach(div => {
            if (div.nextElementSibling && div.nextElementSibling.tagName.toLowerCase() !== 'section') {
                div.classList.add('not-has-section');
            }
        });
    }
}
marginRules();
